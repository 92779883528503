"use client";

import { ChatServiceNameEnum } from "@finbackoffice/fe-core";
import {
    UserAccountContextV2, // refactor
    useRuntimeConfig,
    useDidUpdateEffect,
    ChatServiceContext,
    useSearchParamsQuery,
} from "@finbackoffice/site-core";
import { usePathname } from "next/navigation";
import Script from "next/script";
import { FC, useMemo, useContext, useCallback, useEffect } from "react";
import { RouteNames } from "utils/constants";

export interface IVisibilityState {
    visibility: "maximized" | "minimized" | "hidden";
}

type Props = {
    isUserLoggedIn: boolean;
};

const ChatScript: FC<Props> = ({ isUserLoggedIn }) => {
    const pathname = usePathname();
    const { query } = useSearchParamsQuery();
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const hideChatServiceBadge = useMemo(
        () =>
            (pathname?.includes(RouteNames.CASINO) && query?.gameId) ||
            (isUserLoggedIn && !COMMON_SITE_CONFIGS.chatService.showAfterSignIn),
        [isUserLoggedIn, pathname, query?.gameId, COMMON_SITE_CONFIGS.chatService],
    );
    const { firstName, lastName } = useContext(UserAccountContextV2);
    const { showWidget, hideWidget, setVisible } = useContext(ChatServiceContext);

    useDidUpdateEffect(() => {
        switch (COMMON_SITE_CONFIGS.chatService.name) {
            case ChatServiceNameEnum.LiveChat:
                if (window.LiveChatWidget) {
                    updateVisibility();
                }
                break;
            case ChatServiceNameEnum.Zendesk:
                if (typeof window.zE === "function") {
                    updateVisibility();
                }
                break;
            case ChatServiceNameEnum.Tawk:
                if (window.Tawk_API) {
                    updateVisibility();
                }
                break;
            default:
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname, isUserLoggedIn]);

    const updateVisibility = useCallback(() => {
        if (hideChatServiceBadge) {
            hideWidget();
        } else {
            showWidget();
        }
    }, [hideChatServiceBadge, hideWidget, showWidget]);

    const onLiveChatServiceReady = useCallback(
        (data: { state: IVisibilityState }) => {
            if (isUserLoggedIn && (firstName || lastName)) {
                LiveChatWidget.call(
                    "set_customer_name",
                    `${firstName}${lastName ? ` ${lastName}` : ""}`,
                );
            }
            setVisible(data.state.visibility === "minimized");
        },
        [firstName, isUserLoggedIn, lastName, setVisible],
    );

    const onVisibilityChanged = useCallback(
        (data: IVisibilityState) => {
            setVisible(data.visibility === "minimized");
        },
        [setVisible],
    );

    const onLiveChatScriptLoad = useCallback(() => {
        LiveChatWidget.once("ready", onLiveChatServiceReady);
        LiveChatWidget.on("visibility_changed", onVisibilityChanged);
        updateVisibility();
    }, [onLiveChatServiceReady, onVisibilityChanged, updateVisibility]);

    useEffect(() => {
        return () => {
            if (window.LiveChatWidget && LiveChatWidget._h) {
                LiveChatWidget.off("visibility_changed", onVisibilityChanged);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onZendeskScriptLoad = useCallback(() => {
        updateVisibility();
    }, [updateVisibility]);

    const onTawkScriptLoad = useCallback(() => {
        window.Tawk_API = window.Tawk_API || {};
        if (hideChatServiceBadge) {
            window.Tawk_API.autoStart = false;
        } else {
            window.Tawk_API.autoStart = true;
        }

        window.Tawk_API.onChatHidden = () => {
            setVisible(false);
        };

        window.Tawk_API.onChatMinimized = () => {
            setVisible(!window.Tawk_API.isChatHidden());
        };

        window.Tawk_API.onLoad = () => {
            setVisible(!window.Tawk_API.isChatHidden());
            updateVisibility();
        };
    }, [hideChatServiceBadge, setVisible, updateVisibility]);

    const renderChatScript = useMemo(() => {
        let content;
        switch (COMMON_SITE_CONFIGS.chatService.name) {
            case ChatServiceNameEnum.LiveChat:
                content = (
                    <Script
                        src="/assets/js/livechat.js"
                        id={COMMON_SITE_CONFIGS.chatService.id}
                        onLoad={onLiveChatScriptLoad}
                        async
                    />
                );
                break;
            case ChatServiceNameEnum.Zendesk:
                content = (
                    <Script
                        onLoad={onZendeskScriptLoad}
                        id="ze-snippet"
                        async
                        src={`https://static.zdassets.com/ekr/snippet.js?key=${COMMON_SITE_CONFIGS.chatService.id}`}
                    />
                );
                break;
            case ChatServiceNameEnum.Tawk:
                content = (
                    <Script
                        async
                        src={`https://embed.tawk.to/${COMMON_SITE_CONFIGS.chatService.id}`}
                        onLoad={onTawkScriptLoad}
                    />
                );
                break;
            default:
                content = null;
        }

        return !!COMMON_SITE_CONFIGS.chatService.id && content;
    }, [
        COMMON_SITE_CONFIGS.chatService.name,
        COMMON_SITE_CONFIGS.chatService.id,
        onLiveChatScriptLoad,
        onZendeskScriptLoad,
        onTawkScriptLoad,
    ]);

    return renderChatScript;
};

export default ChatScript;
