"use client";

import { FC, useContext, useEffect, useMemo } from "react";
import { BannersContext, I18nextProvider, PopupBannersContext } from "@finbackoffice/site-core";
import { IBannerResponse, IPopupResponse } from "@finbackoffice/clientbff-client";
import { CommonTranslationsContext } from "contexts";

export const Page: FC<{
    children: React.ReactNode;
    translations: Record<string, any>;
    banners?: IBannerResponse[];
    popupBanners?: IPopupResponse[];
}> = ({ children, translations, banners, popupBanners }) => {
    const translationsContext = useContext(CommonTranslationsContext);
    const { setBannersState } = useContext(BannersContext);
    const { setPopups } = useContext(PopupBannersContext);

    useEffect(() => {
        setBannersState(banners || []);
    }, [banners, setBannersState]);

    useEffect(() => {
        setPopups(popupBanners || []);
    }, [popupBanners, setPopups]);

    const value = useMemo(
        () => ({
            ...translationsContext.translations,
            ...translations,
        }),
        [translations, translationsContext.translations],
    );

    return <I18nextProvider translations={value}>{children}</I18nextProvider>;
};
